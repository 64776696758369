import { css } from "linaria";
import { styled } from "linaria/react";
import { rgba } from "polished";
import React from "react";
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import { theme } from "../../../Shared/Theme/Theme";

export interface QuestionOptionProps {
  className?: string;
  selected: boolean;
  clicked: () => void;
  title: string;
}

const styles = css``;

const IconWrapper = styled.div<{ selected: boolean }>`
  font-size: 1.4rem;
  font-weight: 300;
  transition: all 200ms ease-in-out;
  display: flex;
  align-items: center;
  svg {
    transition: all 200ms ease-in-out;
    color: ${(props) =>
      props.selected ? theme.color.primary : rgba(theme.color.primary, 0.2)};
  }
`;

export const QuestionOption = (props: QuestionOptionProps) => {
  const { className = "", selected, clicked, title } = props;
  return (
    <article
      {...props}
      onClick={clicked}
      className={`${className} ${styles} font-thin flex justify-between text-primary-dark text-base md:text-lg rounded-lg shadow-md w-full px-6 md:px-8 py-6 cursor-pointer transition-all duration-300 ease-in-out hover:shadow-lg`}
    >
      {title}
      <IconWrapper className="ml-4" selected={selected}>
        <BsChevronRight className="hidden lg:block text-2xl" />
        <BsChevronDown className="block lg:hidden" />
      </IconWrapper>
    </article>
  );
};
