import React, { ReactNode } from "react";

export interface HomeSectionHeadingProps {
  className?: string;
  children: ReactNode;
}

export const HomeSectionHeading = (props: HomeSectionHeadingProps) => {
  const { className = "" } = props;

  return (
    <h2
      data-sal-duration="700"
      data-sal="slide-up"
      data-sal-easing="ease"
      className={`${className} text-primary text-4xl lg:text-6xl font-bold mb-6`}
    >
      {props.children}
    </h2>
  );
};
