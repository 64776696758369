import { Link } from "gatsby";
import GatsbyImage from "gatsby-image";
import { css } from "linaria";
import React from "react";
import { Faq } from "../../../../lib/model/Home";
import { generateBlogUrl } from "../../../../lib/utils/generate-blog-url";
import { ContentRenderer } from "../../../Shared/ContentRenderer/ContentRenderer";
import { theme } from "../../../Shared/Theme/Theme";

export interface FaqAnswerProps extends Faq.FaqQuestion {
  className?: string;
}

const blogLinkStyles = css`
  background: ${theme.color.primaryGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const imageStyles = css`
  height: 200px;

  @media (min-width: 640px) {
    height: 350px;
  }

  @media (min-width: 1024px) {
    height: 300px;
  }

  @media (min-width: 1280px) {
    height: 400px;
  }

  @media (min-width: 1536px) {
    height: 450px;
  }
`;

export const FaqAnswer = (props: FaqAnswerProps) => {
  const { className = "", question, answer, image, blog } = props;
  return (
    <article className={`${className} mt-16 lg:mt-0`}>
      <div
        data-sal-duration="700"
        data-sal="slide-up"
        data-sal-easing="ease"
        className={`${imageStyles} image rounded-xl overflow-hidden shadow-xl`}
      >
        <GatsbyImage className="h-full" fluid={image.childImageSharp.fluid} />
      </div>
      <h4
        data-sal-duration="700"
        data-sal="slide-up"
        data-sal-delay={200}
        data-sal-easing="ease"
        className="title mt-10 text-primary-dark text-2xl lg:text-3xl"
      >
        {question}
      </h4>
      <p
        data-sal-duration="700"
        data-sal="slide-up"
        data-sal-delay={400}
        data-sal-easing="ease"
        className="answer mt-5 text-gray-500 lg:text-lg font-light"
      >
        <ContentRenderer source={answer} />
      </p>
      <div
        data-sal-duration="700"
        data-sal="slide-up"
        data-sal-delay={600}
        data-sal-easing="ease"
        className="h-5 mt-3"
      >
        {blog && (
          <Link to={generateBlogUrl(blog.slug)}>
            <div className={`${blogLinkStyles} lg:text-lg `}>Čítať viac</div>
          </Link>
        )}
      </div>
    </article>
  );
};
