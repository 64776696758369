import GatsbyImage from "gatsby-image";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";
import { Container } from "../../Shared/Container/Container";
import { CtaButton } from "../../Shared/CtaButton/CtaButton";
import BlueWaveSvg from "./assets/blue-wave.svg";
import WhiteWaveSvg from "./assets/white-wave.svg";
import { ScrollIcon } from "./components/ScrollIcon";
import { useHero } from "./hooks/useHero";

export interface HeroProps {
  className?: string;
}

const Wave = styled.img`
  width: 100%;
  /* position: absolute; */
  left: 0;
  /* top: 100px; */
  z-index: -10;
  /* display: none; */
  object-fit: contain;

  display: flex;
  @media (min-width: 1280px) {
  }
`;

const whiteWaveStyles = css`
  transform: translateZ(-10px);

  margin-top: -200px;

  @media (min-width: 768px) {
    margin-top: -200px;
  }

  @media (min-width: 1024px) {
    margin-top: -250px;
  }

  @media (min-width: 1280px) {
    margin-top: -25vw;
  }
`;

const blueWaveStyles = css`
  overflow: hidden;
  transform: translateZ(-1px);
  z-index: -100;
`;

const heroStyles = css`
  max-height: 950px;
`;

const wavesStyle = css`
  margin-top: 100px;
  margin-left: -200px;
  /* display: none; */
  min-width: 1000px;

  @media (min-width: 640px) {
  }

  @media (min-width: 768px) {
    margin-top: 250px;
    margin-left: 0px;
  }

  @media (min-width: 1024px) {
    margin-top: 0px;
  }

  @media (min-width: 1280px) {
    margin-top: -100px;
  }

  @media (min-width: 1536px) {
    margin-top: -200px;
  }
`;

const momAndChildImageStyles = css`
  object-fit: cover;
  max-height: 500px;
  overflow: hidden;

  @media (min-width: 334px) {
    max-height: 400px;
  }

  @media (min-width: 768px) {
    max-height: 500px;
  }

  @media (min-width: 1024px) {
    max-height: 700px;
  }
`;

const imageContainerClass = css`
  position: absolute;
  top: 600px;

  @media (min-width: 442px) {
    top: 500px;
  }

  @media (min-width: 768px) {
    top: 500px;
  }

  @media (min-width: 1024px) {
    top: 300px;
  }

  @media (min-width: 1280px) {
    top: 150px;
  }

  @media (min-width: 1536px) {
    top: 180px;
  }
`;
const heroInfoClass = css``;

const heroImageClass = css`
  width: 300px;
  margin: auto;

  @media (min-width: 334px) {
    width: 320px;
  }

  @media (min-width: 510px) {
    width: 400px;
  }

  @media (min-width: 768px) {
    width: 400px;
  }

  @media (min-width: 768px) {
    width: 500px;
  }

  @media (min-width: 1280px) {
    width: 600px;
  }
`;

export const Hero = (props: HeroProps) => {
  const hero = useHero();

  return (
    <section
      className={`${heroStyles} pt-8 xl:pt-20 2xl:pt-48 overflow-hidden`}
    >
      <Container className="relative">
        <ScrollIcon />
        <div className="content grid grid-cols-1 lg:grid-cols-2">
          <div className="hero-info md:ml-10 z-20">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-primary mb-6 text-center lg:text-left">
              {hero.heading}
            </h1>
            <p className="text-gray-500 text-md lg:text-lg w-full lg:w-4/6 text-center lg:text-left">
              {hero.subheading}
            </p>
            <div className="button-wrapper flex mt-12 justify-center lg:justify-start">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://monzun.auksys.com/master/zakaznikadd.php"
              >
                <CtaButton>Prihlásiť sa do klubu</CtaButton>
              </a>
            </div>
          </div>
        </div>
      </Container>
      <div className={wavesStyle}>
        <div className={blueWaveStyles}>
          <Wave src={BlueWaveSvg} />
        </div>
        <Container
          className={`mx-auto left-0 right-0 absolute grid grid-cols-1 lg:grid-cols-2 ${imageContainerClass}`}
        >
          <div></div>
          <div className={momAndChildImageStyles}>
            <GatsbyImage
              className={heroImageClass}
              fluid={hero.image.childImageSharp.fluid}
            />
          </div>
        </Container>
        <Wave className={whiteWaveStyles} src={WhiteWaveSvg} />
        <div className="w-full bg-white h-4"></div>
      </div>
    </section>
  );
};
