import React, { useState } from "react";
import { FaqSection } from "../components/Home/FaqSection/FaqSection";
import { Hero } from "../components/Home/Hero/Hero";
import { NeverForget } from "../components/Home/NeverForget/NeverForget";
import { OurPremises } from "../components/Home/OurPremises/OurPremises";
import Layout from "../components/Shared/Layout/Layout";
import { SectionsWrapper } from "../components/Shared/SectionsWrapper/SectionsWrapper";
import { SEO } from "../components/Shared/SEO/SEO";

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Monzun BB | plávanie pre deti a bábätka Banská Bystrica"
        useTemplate={false}
      />

      <SectionsWrapper>
        <Hero />
        <OurPremises />
        <FaqSection />
        {/* <Testimonials /> */}
        <NeverForget />
      </SectionsWrapper>
    </Layout>
  );
};

export default IndexPage;
