import { graphql, useStaticQuery } from "gatsby";
import { Faq } from "../../../../lib/model/Home";

export const useFaqSection = (): Faq.FaqSection => {
  const {
    strapiHomePage: { FaqSection },
  } = useStaticQuery(graphql`
    query useFaqSection {
      strapiHomePage {
        FaqSection {
          title
          subtitle
          FaqQuestions {
            question
            image {
              childImageSharp {
                fluid(quality: 90, maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
            blog {
              slug
            }
            answer
          }
        }
      }
    }
  `);

  return FaqSection;
};
