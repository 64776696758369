import React, { useRef, useState } from "react";
import { Container } from "../../Shared/Container/Container";
import { HomeSectionHeading } from "../../Shared/HomeSectionHeading/HomeSectionHeading";
import { HomeSectionSubheading } from "../../Shared/HomeSectionSubheading/HomeSectionSubheading";
import { FaqAnswer } from "./components/FaqAnswer";
import { QuestionOption } from "./components/QuestionOption";
import { useFaqSection } from "./hooks/useFaqSection";

export interface FaqSectionProps {
  className?: string;
}

export const FaqSection = (props: FaqSectionProps) => {
  const { className = "" } = props;
  const faqSection = useFaqSection();
  const answerCompRef = useRef<HTMLDivElement>(null);
  const scrollToAnswer = () => {
    if (answerCompRef.current !== null) {
      answerCompRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const [questionIndex, setQuestionIndex] = useState(0);

  return (
    <section className={`className`}>
      <Container>
        <header ref={answerCompRef}>
          <HomeSectionHeading>{faqSection.title}</HomeSectionHeading>
          <HomeSectionSubheading className="mr-auto mx-0">
            {faqSection.subtitle}
          </HomeSectionSubheading>
        </header>
        <div className="space-x-0 faq flex flex-col lg:flex-row lg:space-x-14 xl:space-x-20 2xl:space-x-32 mt-16">
          <div className="questions w-full lg:w-5/12  flex-col flex space-y-8">
            {faqSection.FaqQuestions.map((question, index) => (
              <QuestionOption
                key={question.question}
                data-sal-duration="700"
                data-sal="slide-up"
                data-sal-delay={index * 150}
                data-sal-easing="ease"
                clicked={() => {
                  setQuestionIndex(index);
                  scrollToAnswer();
                }}
                title={question.question}
                selected={index === questionIndex}
              />
            ))}
          </div>
          <div  className="answer w-full lg:w-7/12">
            <FaqAnswer {...faqSection.FaqQuestions[questionIndex]} />
          </div>
        </div>
      </Container>
    </section>
  );
};
