import React from "react";
import { ContentRenderer } from "../../../Shared/ContentRenderer/ContentRenderer";
import BlueRecSvg from "../assets/blue-rec.svg";

export interface PremiseAmenityInfoProps {
  className?: string;
  title: string;
  content: string;
}

export const PremiseAmenityInfo = (props: PremiseAmenityInfoProps) => {
  const { title, content } = props;
  return (
    <div className={`flex flex-auto flex-col justify-center`}>
      <header className="relative ml-3">
        <h4 className="text-primary text-3xl xl:text-4xl font-bold mb-2 xl:mb-6 z-10 relative">{title}</h4>
        <img src={BlueRecSvg} alt="blue rec" className="h-14 w-14 absolute -top-6 -left-6 z-0" />
      </header>
      <ContentRenderer className="text-base xl:text-lg" source={content} />
    </div>
  );
};
