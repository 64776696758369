import { graphql, useStaticQuery } from "gatsby";
import { OurPremises } from "../../../../lib/model/Home";

export const useOurPremises = (): OurPremises.OurPremises => {
  const {
    strapiHomePage: { OurPremises },
  } = useStaticQuery(graphql`
    query useOurPremises {
      strapiHomePage {
        OurPremises {
          title
          subtitle
          OurPremiseAmenity {
            title
            content
            desktop: image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
            mobile: image {
              childImageSharp {
                fluid(maxWidth: 300, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  `);

  return OurPremises;
};
