import { css } from "linaria";
import React from "react";
import { Container } from "../../Shared/Container/Container";
import { HomeSectionHeading } from "../../Shared/HomeSectionHeading/HomeSectionHeading";
import { HomeSectionSubheading } from "../../Shared/HomeSectionSubheading/HomeSectionSubheading";
import { PremiseAmenity } from "./components/PremiseAmenity";
import { useOurPremises } from "./hooks/useOurPremises";

export interface OurPremisesProps {
  className?: string;
}

const ourPremisesStyles = css`
  margin-top: 60px !important;
`;

export const OurPremises = (props: OurPremisesProps) => {
  const {} = props;
  const ourPremises = useOurPremises();
  return (
    <section className={`${ourPremisesStyles} bg-white -mt-24`}>
      <Container>
        <header className="mb-14 lg:mb-28">
          <HomeSectionHeading className="text-center">
            {ourPremises.title}
          </HomeSectionHeading>
          <HomeSectionSubheading className="text-center mx-auto">
            {ourPremises.subtitle}
          </HomeSectionSubheading>
        </header>
        <div className="amenities space-y-12 lg:spacey-y24">
          {ourPremises.OurPremiseAmenity.map((amenity, index) => (
            <PremiseAmenity
              key={amenity.title}
              imageOnLeft={index % 2 == 0}
              {...amenity}
            />
          ))}
        </div>
      </Container>
    </section>
  );
};
