import React from "react";
import { FaExclamation } from "react-icons/fa";
import { Container } from "../../Shared/Container/Container";
import { ContentRenderer } from "../../Shared/ContentRenderer/ContentRenderer";
import { useHomeNeverForget } from "./hooks/useHomeNeverForget";
import ExclSvg from "./assets/exl.svg";

export interface NeverForgetProps {
  className?: string;
}

export const NeverForget = (props: NeverForgetProps) => {
  const { className = "" } = props;
  const warning = useHomeNeverForget();

  return (
    <Container>
      <section
        data-sal-duration="700"
        data-sal="slide-up"
        data-sal-easing="ease"
        className={`${className} flex lg:space-x-8 mb-16`}
      >
        <div className="icon hidden lg:flex">
          <img className="h-40 w-40" src={ExclSvg} alt="excl icon" />
        </div>
        <div className="content flex flex-col justify-center">
          <h3 className="text-4xl text-primary font-thin">{warning.title}</h3>
          <ContentRenderer source={warning.content} />
        </div>
      </section>
    </Container>
  );
};
