import React, { ReactNode } from "react";

export interface HomeSectionSubheadingProps {
  className?: string;
  children: ReactNode;
}

export const HomeSectionSubheading = (props: HomeSectionSubheadingProps) => {
  const { children, className = "" } = props;
  return (
    <p
      data-sal-duration="700"
      data-sal="slide-up"
      data-sal-delay={350}
      data-sal-easing="ease"
      className={`${className} text-base lg:text-lg lg:w-2/5  text-gray-500`}
    >
      {children}
    </p>
  );
};
