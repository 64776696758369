import { graphql, useStaticQuery } from "gatsby";
import { HomeWarning } from "../../../../lib/model/Home";

export const useHomeNeverForget = (): HomeWarning.Warning => {
  const {
    strapiHomePage: { HomeWarning },
  } = useStaticQuery(graphql`
    query useHomeNeverForget {
      strapiHomePage {
        HomeWarning {
          title
          content
        }
      }
    }
  `);

  return HomeWarning  
};
