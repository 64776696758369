import GatsbyImage from "gatsby-image";
import React from "react";
import ReactMarkdown from "react-markdown";
import { OurPremises } from "../../../../lib/model/Home";
import { ContentRenderer } from "../../../Shared/ContentRenderer/ContentRenderer";
import { PremiseAmenityInfo } from "./PremiseAmenityInfo";

export interface PremiseAmenityProps extends OurPremises.OurPremiseAmenity {
  className?: string;
  imageOnLeft?: boolean;
}

export const PremiseAmenity = (props: PremiseAmenityProps) => {
  const { title, content, imageOnLeft = true, mobile, desktop } = props;
  return (
    <article className={``}>
      <div className="desktop grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-20 xl:gap-32 hidden lg:grid">
        {!imageOnLeft && (
          <div
            className="flex"
            data-sal-duration="700"
            data-sal="slide-right"
            data-sal-easing="ease"
          >
            <PremiseAmenityInfo title={title} content={content} />
          </div>
        )}
        <div className="image  w-full flex-auto rounded-2xl shadow-2xl overflow-hidden">
          <GatsbyImage
            className={"h-full"}
            fluid={[
              desktop.childImageSharp.fluid,
              {
                ...mobile.childImageSharp.fluid,
                media: `(max-width: 768px)`,
              },
            ]}
          />
        </div>
        {imageOnLeft && (
          <div
            className="flex"
            data-sal-duration="700"
            data-sal="slide-left"
            data-sal-easing="ease"
          >
            <PremiseAmenityInfo title={title} content={content} />
          </div>
        )}
      </div>
      <div
        className="mobile lg:hidden flex space-y-12 flex-col"
        data-sal-duration="700"
        data-sal="slide-up"
        data-sal-easing="ease"
      >
        <div className="image  w-full flex-auto rounded-2xl shadow-2xl overflow-hidden">
          <GatsbyImage
            className={"h-full w-full"}
            fluid={[
              desktop.childImageSharp.fluid,
              {
                ...mobile.childImageSharp.fluid,
                media: `(max-width: 768px)`,
              },
            ]}
          />
        </div>
        <PremiseAmenityInfo title={title} content={content} />
      </div>
    </article>
  );
};
