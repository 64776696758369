import { css } from "linaria";
import React from "react";

export interface ScrollIconProps {
  className?: string;
}

const iconsScrollStyles = css`
  display: flex;
  justify-content: center;

  filter: opacity(0.9);

  position: absolute;
  bottom: -15vh;
  right: 0;
  left: 0;

  @media (min-width: 1536px) { 
    bottom: -27vh;
  }

  z-index: 20;

  margin: auto;

  .circle-1,
  .circle-2 {
    animation: scroll 2s infinite linear;
    opacity: 0;
  }

  .circle-2 {
    animation-delay: 1s;
  }

  @keyframes scroll {
    0% {
      cy: 4;
      opacity: 0;
    }
    45%,
    55% {
      opacity: 1;
      cy: 9;
    }
    100% {
      cy: 14;
      opacity: 0;
    }
  }
`;

export const ScrollIcon = (props: ScrollIconProps) => {
  const { className = "" } = props;
  return (
    <div className={iconsScrollStyles}>
      <svg width="25px" viewBox="0 0 14.334 24.75">
        <circle className="circle-1" fill="#e9e9e9" cx="7.167" cy="6" r="1.2" />
        <circle className="circle-2" fill="#e9e9e9" cx="7.167" cy="6" r="1.2" />
        <path
          stroke="#e9e9e9"
          fill="transparent"
          d="M7.167,0.5C3.485,0.5,0.5,3.485,0.5,7.167v10.416                   c0,3.682,2.985,6.667,6.667,6.667s6.667-2.985,6.667-6.667V7.167C13.834,3.485,10.849,0.5,7.167,0.5z"
        />
      </svg>
    </div>
  );
};
