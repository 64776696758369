import { graphql, useStaticQuery } from "gatsby";
import { FluidObject } from "gatsby-image";
export interface Hero {
  heading: string;
  subheading: string;
  image: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
}

export const useHero = (): Hero => {
  const {
    strapiHomePage: { Hero },
  } = useStaticQuery(graphql`
    query HeroQuery {
      strapiHomePage {
        Hero {
          subheading
          heading
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `);

  return Hero;
};
